import React, {useEffect} from 'react'
import  { BreakpointProvider } from 'react-socks'
import { useDispatch } from 'react-redux'
import 'lazysizes'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header/header'
import Footer from './footer'
import AlertPopin from './alertPopin'

import scrollManager from '../utils/scrollManager'

import '../styles/styles.scss'

// fonts
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600.css"

import { changeLang, setLaboEmptyOther, setCurrentMainTag } from '../store/actions'


const Layout = ({initLocale, frUrl, enUrl, children, isHome = false, mainTag = false, headerType = 'reg', isSolution = false, is404 = false}) => {
  const dispatch = useDispatch()
  dispatch(changeLang(initLocale))

  const datas = useStaticQuery(graphql`
    query {
      news: allContentfulNews {
        nodes {
          distributionChannel {
            title
          }
          title
          node_locale
          createdAt
          publicationDate
          mainTag {
            title
          }
        }
      }
      events: allContentfulEvent {
        nodes {
          distributionChannel {
            title
          }
          title
          node_locale
          startDate
          mainTag {
            title
          }
        }
      }
      pages: allContentfulPage {
        nodes {
          distributionChannel {
            title
          }
          title
          slug
          node_locale
          mainTag {
            title
          }
        }
      }
      laboPopin: allContentfulLaboPopin {
        nodes {
          node_locale
          emptyTag {
            title
          }
          distributionChannel {
            title
          }
          emptyNews {
            title
            node_locale
            createdAt
            publicationDate
          }
          emptyEvents {
            title
            node_locale
            startDate
          }
          emptyPages {
            ... on ContentfulContact {
              title
              node_locale
              slug
            } 
            ... on ContentfulPage {
              title
              node_locale
              slug
            } 
          }
        }
      }
    }
  `)
  
  const getItems = (items, emptyTag) => {
    let emptyEls = items.filter(news => (news.node_locale === initLocale && news.title !== null && news.title !== 'Dummy content' && news.mainTag.title === emptyTag))

    emptyEls = emptyEls.filter(news => news.distributionChannel.filter(channel => channel.title === 'Terega solutions').length > 0)

    if (emptyEls.length > 3) {
      emptyEls = emptyEls.slice(0, 3)
    }

    return emptyEls
  }

  setTimeout(() => {
    datas.laboPopin.nodes.forEach(item => {
      if (item.node_locale === initLocale && item.distributionChannel.filter(channel => channel.title === 'Terega solutions').length > 0) {
        const emptyOther = {}

        emptyOther.news = (item.emptyNews !== null && item.emptyNews !== undefined) ? item.emptyNews : getItems(datas.news.nodes, item.emptyTag.title)
        emptyOther.events = (item.emptyEvents !== null && item.emptyNews !== undefined) ? item.emptyEvents : getItems(datas.events.nodes, item.emptyTag.title)
        emptyOther.pages = (item.emptyPages !== null && item.emptyNews !== undefined) ? item.emptyPages : getItems(datas.pages.nodes, item.emptyTag.title)
        
        if (emptyOther.news !== null) {
          dispatch(setLaboEmptyOther(emptyOther))
        }
      }
    })

    // case multiple / single profile
    dispatch(setCurrentMainTag(mainTag))
  }, 0)

  useEffect(() => {
    scrollManager.restart()
    sessionStorage.setItem('prevUrl', sessionStorage.getItem('currentUrl'))
    sessionStorage.setItem('currentUrl', window.location.href)
  }, [])


  return (
    <div className="app">
      <Header frUrl={frUrl} enUrl={enUrl} theme={headerType} isHome={isHome} isSolution={isSolution} />

      <div className={`app-inner flex ${headerType === 'bg-white' ? 'app-inner--has-padding' : '' }`}>
        <BreakpointProvider>
          <div className={`fluid-grid ${is404 ? 'flex' : ''}`}>
            {children}
          </div>
        </BreakpointProvider>
      </div>

      <AlertPopin />

      <Footer frUrl={frUrl} enUrl={enUrl} />

      <svg className="svg-gradients">
          <defs>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="gradientBlue">
                  <stop stopColor="#738dc7" offset="0%"/>
                  <stop stopColor="#cadfe6" offset="100%"/>
              </linearGradient>
              <linearGradient x1="50%" y1="0%" x2="43.99%" y2="107.691%" id="gradientPurple">
                  <stop stopColor="#CED4E3" offset="0%"/>
                  <stop stopColor="#738DC7" offset="100%"/>
              </linearGradient>
              <linearGradient x1="98.571%" y1="-60.73%" x2="50%" y2="100%" id="gradientGreen">
                  <stop stopColor="#E5DACF" offset="0%"/>
                  <stop stopColor="#A3BCB4" offset="100%"/>
              </linearGradient>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="gradientAqua">
                  <stop stopColor="#CADFE6" offset="0%"/>
                  <stop stopColor="#76AEC1" offset="100%"/>
              </linearGradient>

              <linearGradient x1="-10%" y1="50%" x2="50%" y2="100%" id="gradientCo22">
                  <stop stopColor="#D5D8F0" offset="0%"/>
                  <stop stopColor="#D4A385" offset="100%"/>
              </linearGradient>

              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="gradientCo2">
                  <stop stopColor="#D4A385" offset="0%"/>
                  <stop stopColor="#D5D8F0" offset="100%"/>
              </linearGradient>

              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="gradientBlue2">
                  <stop stopColor="#738DC7" offset="0%"/>
                  <stop stopColor="#ebe0d5" offset="100%"/>
              </linearGradient>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="gradientSienne">
                  <stop stopColor="#caa39e" offset="0%"/>
                  <stop stopColor="#ced4e3" offset="100%"/>
              </linearGradient>
              <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="gradientSienne2">
                  <stop stopColor="#E5DACF" offset="0%"/>
                  <stop stopColor="#CAA39E" offset="100%"/>
              </linearGradient>
          </defs>
      </svg>
    </div>
  )
}

export default Layout
